import React, { useContext, useEffect, useState } from "react";
import Styles from "./style/GenerateLink.module.scss";
// import Link from "../img/link.png";
import { AppContext } from "./context";
// import downArrow from "../img/downArrow.png";
import closeImage from "../img/modalClose.png";
import { makeAuthRequest } from "./context/utils";
import Cookies from "js-cookie";
import lang from './language/GenerateLink.json'
import config from '../config/config.json'
import loading from '../img/loading.gif'
import countryCodeList from "country-codes-list"

const myCountryCodesObject = countryCodeList.customList('countryNameEn', '+{countryCallingCode}')

const GenerateLink = () => {
  const {urlLink, octoClients} = config

  const { setOpen, linkType, setLinkType, countryCode, setCountryCode, webappType, setWebappType,
    webAppEmail, setWebAppEmail, webAppPhone, setWebAppPhone, webAppFinalCount, setWebAppFinalCount, webAppPersonaId, 
    setWebAppPersonaId, webAppId, setWebAppId, webAppLicencePlate, setWebAppLicencePlate, webAppRepeat, setWebAppRepeat,
    webAppCaseID, setWebAppCaseID, language, urlGenerating, setUrlGenerating, urlSubmitted, setUrlSubmitted, configPresent, setConfigPresent, 
    personaList, webAppVin, setWebAppVin } = useContext(AppContext);

    const [checked, setChecked] = useState(false)

  const validateData = () => {
    if(webappType === "single"){
      if(Cookies.get("master") === "client_priscorp"){
        if(!checked){
          return {status: false, message: "Declaration not checked"}
        }
      }
      if(configPresent){
        if(config["generateLink"][Cookies.get('master')]["email-mandatory"]){
          if(!webAppEmail){
            return {status: false, message: lang["Email missing"][language]}
          }
        }
  
        if(config["generateLink"][Cookies.get('master')]["phone-mandatory"]){
          if(!webAppPhone){
            return {status: false, message: lang["phone missing"][language]}
          }
          if(!countryCode) return {status: false, message: lang["phone missing"][language]}
        }
  
        if(config["generateLink"][Cookies.get('master')]["license-mandatory"]){
          if(!webAppLicencePlate){
            return {status: false, message: lang["License Plate missing"][language]}
          }
        }

        if(config["generateLink"][Cookies.get('master')]["vin-mandatory"]){
          if(!webAppVin){
            return {status: false, message: "VIN Missing"}
          }
        }
  
        if(config["generateLink"][Cookies.get('master')]["case-mandatory"]){
          if(!webAppCaseID){
            return {status: false, message: lang["case missing"][language]}
          }
        }
      }
      else{
        if(!webAppEmail){
          return {status: false, message: lang["Email missing"][language]}
        }
        if(!webAppPhone){
          return {status: false, message: lang["phone missing"][language]}
        }
        if(!countryCode) return {status: false, message: lang["phone missing"][language]}
      }
    }
    else{
      if(!webAppEmail){
        return {status: false, message: lang["Email missing"][language]}
      }
      if(configPresent && config["generateLink"][Cookies.get('master')]["allowBulkLimit"]){
        if(webAppFinalCount > config["generateLink"][Cookies.get('master')]["bulkLimit"]){
          return {status: false, message: "Bulk count limit exceeded"}
        }
      }
    }

    if(webAppFinalCount <= 0 && webappType === "bulk") return {status: false, message: lang["valid count"][language]}
    else if(webAppPersonaId <= 0 || !webAppPersonaId) return {status: false, message: lang["valid persona"][language]}
    else return { status: true, message: ""}

  }

  const sendDatatoTable = async () => {
    if(urlGenerating) return
    let result = validateData()
    setUrlGenerating(true)
    if(!result.status){
      alert(result.message)
      setUrlGenerating(false)
      return
    }

    const res = await makeAuthRequest(`${urlLink}feed-token-data-urlgen`, {client_id: Cookies.get('client'), phone: `${countryCode}${webAppPhone}`, email: webAppEmail, final_count: webAppFinalCount, generation_time: Date.now(), 
    expiration_time: Date.now() + (43200*60000), persona_id: webAppPersonaId, source: 'Super', link_type: webappType, app_id: webAppId, license_plate: webAppLicencePlate, repeat: webAppRepeat, case_id: webAppCaseID, app_type: linkType, 
    client_type: Cookies.get('clientType'), send_comm_via_api: ["client_507", "client_1217", "client_1121"].includes(Cookies.get('master')), update_details: "", resend: "", existing_token: "", vin: webAppVin})
    
    const data = await res.json()

    setWebAppEmail("")
    setWebAppFinalCount("")
    setWebAppLicencePlate("")
    setWebAppVin("")
    setWebAppPhone("")
    setWebappType("single")
    setWebAppCaseID("")
    setLinkType("custom")

    if(configPresent){
      checkConfig()
    }
    else{
      setWebAppId("")
      setWebAppPersonaId("0")
      setWebAppRepeat(false)
      setCountryCode("")
    }

    if(res.ok){
      setUrlSubmitted(true)
      setTimeout(()=>{
        setOpen(false)
        setUrlSubmitted(false)
      }, 3000)
      setUrlGenerating(false)
      if(webappType === "bulk") return window.open(data.presigned_url, "_blank")
    }
  }

  const onCloseModal = () => {
    setWebAppEmail("")
    setWebAppFinalCount("")
    setWebAppLicencePlate("")
    setWebAppVin("")
    setWebAppPhone("")
    setWebappType("single")
    setLinkType("custom")
    setWebAppCaseID("")
    setOpen(false)
    setUrlGenerating(false)
    setUrlSubmitted(false)

    if(configPresent){
      checkConfig()
    }
    else{
      setWebAppId("")
      setWebAppPersonaId("0")
      setWebAppRepeat(false)
      setCountryCode("")
    }
  }

  const checkConfig = () => {
    if(config["generateLink"][Cookies.get('master')]["appTypeFixed"] === "custom"){
      setLinkType("custom")
    }
    if(config["generateLink"][Cookies.get('master')]["appTypeFixed"] === "default"){
      setLinkType("default")
    }
    if(config["generateLink"][Cookies.get('master')]["appIdFixedValue"] >= 0){
      setWebAppId(config["generateLink"][Cookies.get('master')]["appIdFixedValue"])
    }
    if(config["generateLink"][Cookies.get('master')]["personaIdFixedValue"] >= 0){
      setWebAppPersonaId(config["generateLink"][Cookies.get('master')]["personaIdFixedValue"])
    }
    if(config["generateLink"][Cookies.get('master')]["repeatFixed"] === "yes"){
      setWebAppRepeat(true)
    }
    if(config["generateLink"][Cookies.get('master')]["repeatFixed"] === "no"){
      setWebAppRepeat(false)
    }
    setCountryCode(config["generateLink"][Cookies.get('master')]["countryCode"])
  }

  useEffect(()=>{
    if(Object.keys(config["generateLink"]).includes(Cookies.get('master'))){
      setConfigPresent(true)
      checkConfig()
    }
  },[])

  
  return (
    <div className={Styles.GenerateLink}>
      <div className={Styles.hero}>
        <p className={Styles.linkTitle}>{lang["Generate"][language]}</p>
        <p className={Styles.CloseModal} onClick={onCloseModal}>
          <img src={closeImage} alt='X'/>
        </p>
        <hr />
        <div className={Styles.form}>
          <label>{lang["select"][language]}</label>
          <div className={Styles.formControl}>
            <button
            style={{
              backgroundColor:
                webappType === "single" ? "#157aff" : "#010101",
                cursor: "pointer"
            }}
            onClick={(e) => setWebappType("single")}
            className={Styles.btnEmail}
            type="button"
            >
              {lang["Single"][language]}
            </button>

            {configPresent ? config["generateLink"][Cookies.get('master')]["bulk"] ? 
              <button
              style={{
                backgroundColor: webappType === "bulk" ? "#157aff" : "#010101",
                cursor: "pointer"
              }}
              onClick={(e) => setWebappType("bulk")}
              className={Styles.btnEmail}
              type="button"
              >
                {lang["Bulk"][language]}
              </button> 
              : null
              :
              <button
              style={{
                backgroundColor: webappType === "bulk" ? "#157aff" : "#010101",
                cursor: "pointer"
              }}
              onClick={(e) => setWebappType("bulk")}
              className={Styles.btnEmail}
              type="button"
              >
                {lang["Bulk"][language]}
              </button>
            }

          </div>
          <hr/>
          <label htmlFor="email">{lang["Email Address"][language]}{configPresent ? config["generateLink"][Cookies.get('master')]["email-mandatory"] ? <span>*</span> : null : <span>*</span>}</label>              
          <input
            type="email"
            name="email"
            placeholder={lang["email"][language]}
            value={webAppEmail}
            onChange={(e)=>setWebAppEmail(e.target.value)}
          ></input>
          {webappType === "single" &&
          <div>
            <label htmlFor="phone">{lang["Phone number"][language]}{configPresent ? config["generateLink"][Cookies.get('master')]["phone-mandatory"] ? <span>*</span> : null : <span>*</span>}</label>
            <br></br>
            {(octoClients.includes(Cookies.get("master")) && Cookies.get("master") != "client_1121") ? 
            <select value={countryCode} onChange={(e)=>setCountryCode(e.target.value)} disabled={configPresent ? config["generateLink"][Cookies.get('master')]["countryCode"] ? true : false : false} style={{width: "170px"}}>
              <option value="+39">Italy (+39)</option>
            </select> : <select value={countryCode} onChange={(e)=>setCountryCode(e.target.value)} disabled={configPresent ? config["generateLink"][Cookies.get('master')]["countryCode"] ? true : false : false} style={{width: "170px"}}>
              <option value="">{lang["Country code"][language]}</option>
              {Object.keys(myCountryCodesObject).sort().map((item, index)=>{
                return <option key={index} value={myCountryCodesObject[item]}>{item} ({myCountryCodesObject[item]})</option>
              })}
            </select>}
            <input
              type="text"
              name="phone"
              placeholder={configPresent ? config["generateLink"][Cookies.get('master')]["phone-placeholder"]: lang["Phone"][language]}
              value={webAppPhone}
              disabled = {configPresent ? config["generateLink"][Cookies.get('master')]["phone-disabled"] ? true : false : false}
              onChange={(e)=>setWebAppPhone(e.target.value)}
              style={{width: "423px", marginLeft: "7px"}}
            ></input>
          </div>}

          {webappType === "single" && (
            <div>
              <label htmlFor="CaseID">{lang["Case ID"][language]}{configPresent ? config["generateLink"][Cookies.get('master')]["case-mandatory"] ? <span>*</span> : null : null}</label>
              <input
                type="text"
                name="CaseID"
                placeholder={lang["CaseID"][language]}
                onChange={(e)=>setWebAppCaseID(e.target.value)}
              ></input>
            </div>
          )}

          <label>{lang["App Type"][language]}<span>*</span></label>
          <select value={linkType} onChange={(e) => setLinkType(e.target.value)} disabled={configPresent ? config["generateLink"][Cookies.get('master')]["appTypeFixed"] ? true : false: false}>
            <option value="custom">{lang["custom"][language]}</option>
            <option value="default">{lang["default"][language]}</option>
          </select>

          {linkType === "custom" && (

            personaList.length === 0 ? 
            <div>
              <label htmlFor="personaID">Persona ID<span>*</span></label>
              <input
                type="number"
                name="personaID"
                placeholder={lang["no persona"][language]}
                disabled={true}
              ></input>
            </div>:
            <div>
              <label htmlFor="personaID">Persona ID<span>*</span></label>
              <select value={webAppPersonaId} onChange={(e) => setWebAppPersonaId(e.target.value.toString())} disabled={configPresent ? config["generateLink"][Cookies.get('master')]["personaIdFixedValue"] > 0 ? true : false: false}>
                <option value= ""></option>
                {personaList.map((item, index) => {
                  return <option key={index} value={`${Object.keys(item)[0]}`}>{item[Object.keys(item)[0]].replace(".txt", "") ? `${Object.keys(item)[0]} - ${item[Object.keys(item)[0]].replace(".txt", "")}`: `${Object.keys(item)[0]}`}</option>
                })}
              </select>
            </div>
          )}
          {linkType === "default" && (
            <div>
              <label htmlFor="appID">App ID<span>*</span></label>
              <input
                type="number"
                name=" appID"
                placeholder={lang["App"][language]}
                value={webAppId}
                onChange={(e)=>setWebAppId(e.target.value)}
                disabled={configPresent ? config["generateLink"][Cookies.get('master')]["appIdFixedValue"] > 0 ? true : false : false}
              ></input>
            </div>
          )}

          {webappType === "single" && (
            <div>
              <label htmlFor="License">{lang["License Plate"][language]}{configPresent ? config["generateLink"][Cookies.get('master')]["license-mandatory"] ? <span>*</span> : null : null}</label>
              <input
                type="text"
                name="License"
                disabled={configPresent ? config["generateLink"][Cookies.get('master')]["licence-disabled"] ? true : false : false }
                placeholder={configPresent ? config["generateLink"][Cookies.get('master')]["licence-placeholder"]: "xx-xx-xx"}
                onChange={(e)=>setWebAppLicencePlate(e.target.value)}
              ></input>
            </div>
          )}

          {webappType === "single" && (
            <div>
              <label htmlFor="VIN">VIN{configPresent ? config["generateLink"][Cookies.get('master')]["vin-mandatory"] ? <span>*</span> : null : null}</label>
              <input
                type="text"
                name="VIN"
                disabled={configPresent ? config["generateLink"][Cookies.get('master')]["vin-disabled"] ? true : false : false }
                onChange={(e)=>setWebAppVin(e.target.value)}
              ></input>
            </div>
          )}

          {webappType === "bulk" && 
          <div>
            <label htmlFor="count">{lang["Count"][language]}</label>
            <input
              type="number"
              min="1"
              name="count"
              placeholder={lang["Count"][language]}
              value={webAppFinalCount}
              onChange={(e)=>setWebAppFinalCount(e.target.value)}
            ></input>
          </div>}

          <label>{lang["Repeat"][language]}</label>
          <select value={webAppRepeat ? "yes" : "no"} onChange={(e) => {
            if(e.target.value === "yes") setWebAppRepeat(true)
            else setWebAppRepeat(false)
            }}
            disabled={configPresent ? config["generateLink"][Cookies.get('master')]["repeatFixed"] ? true : false : false}
            >
            <option value="yes">{lang["yes"][language]}</option>
            <option value="no">{lang["no"][language]}</option>
          </select>

          {Cookies.get("master") === "client_priscorp" && <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <input
              type="checkbox"
              style={{ margin: 0, width:"20px" }}
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <label style={{ fontSize: "14px", textAlign: "left" }}>
            I agree to receive SMS notifications, including vehicle inspection links. Message and data rates may apply. Reply STOP to unsubscribe.
            </label>
          </div>}

          <div className={Styles.shareLink}>
            <button type="submit" onClick={()=>sendDatatoTable()}>{ urlGenerating ? <img src={loading}></img> : lang["Share"][language]}</button>
            {urlSubmitted && <p>{lang["Data submitted"][language]}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateLink;