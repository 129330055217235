import { useState } from 'react'

const useAppData = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [language, setLanguage] = useState("English");
  const [languageList, setLanguageList] = useState([]);
  const [role, setRole] = useState("master");
  const [loggedIn, setLoggedIn] = useState(false);
  const [module, setModule] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNavProfileOpen, setIsNavProfileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [swipe, setSwipe] = useState("carLevelResult");
  const [openMenu , setOpenMenu] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [preInspectionId, setPreInspectionId] = useState();
  const [preInspectionList, setPreInspectionList] = useState([])
  const [currentPreInspectionPage, setCurrentPreInspectionPage] = useState(1)
  const [totalPages, setTotalPages] = useState([])
  const [loading, setLoading] = useState(true)
  const [date, setDate] = useState('')
  const [search, setSearch] = useState('')
  const [agentSearch, setAgentSearch] = useState('')
  const [showRemarkModal, setShowRemarkModal] = useState(false)
  const [remark, setRemark] = useState('')
  const [webappType, setWebappType] = useState("single")
  const [urlgenList, setUrlgenList] = useState([])
  const [totalUrlgenPages, setTotalUrlgenPages] = useState([])
  const [currentUrlgenPage, setCurrentUrlgenPage] = useState(1)
  const [urlgenLoading, setUrlgenLoading] = useState(true)
  const [urlgenSearch, setUrlgenSearch] = useState('')
  const [urlgenDate, setUrlgenDate] = useState('')
  const [appTypeOpen, setAppTypeOpen] = useState(false);
  const [linkType, setLinkType] = useState("custom");
  const [navLaunguageOpen, setNavLaunguageOpen] = useState(false);
  const [webAppEmail, setWebAppEmail] = useState("")
  const [webAppPhone, setWebAppPhone] = useState("")
  const [webAppFinalCount, setWebAppFinalCount] = useState("")
  const [webAppPersonaId, setWebAppPersonaId] = useState("0")
  const [webAppId, setWebAppId] = useState("")
  const [webAppLicencePlate, setWebAppLicencePlate] = useState("")
  const [webAppRepeat, setWebAppRepeat] = useState(false)
  const [webAppCaseID, setWebAppCaseID] = useState("")
  const [photowiseDetails, setPhotowiseDetails] = useState({})
  const [photowiseLoading, setPhotowiseLoading] = useState(true)
  const [agentApprove, setAgentApprove] = useState("Disapproved")
  const [selectedImage, setSelectedImage] = useState({})
  const [policyNumber, setPolicyNumber] = useState("")
  const [approvalType, setApprovalType] = useState("")
  const [showPolicyModal, setShowPolicyModal] = useState(false)
  const [policyEmail, setPolicyEmail] = useState("")
  const [policyLoading, setPolicyLoading] = useState("loading")
  const [showApproveModal, setShowApproveModal] = useState(false)
  const [clickedPart, setClickedPart] = useState(0)
  const [searchInspection, setSearchInspection] = useState("")
  const [openImageModal, setOpenImageModal] = useState(false)
  const [imageList, setImageList] = useState([])
  const [urlList, setUrlList] = useState([])
  const [showImageError, setShowImageError] = useState("")
  const [openImageSuccessModal, setOpenImageSuccessModal] = useState(false)
  const [urlGenerating, setUrlGenerating] = useState(false)
  const [loginError, setLoginError] = useState("")
  const [urlSubmitted, setUrlSubmitted] = useState(false)
  const [showCarRemarkModal, setShowCarRemarkModal] = useState(false)
  const [carRemark, setCarRemark] = useState("")
  const [countryCode, setCountryCode] = useState("")
  const [configPresent, setConfigPresent] = useState(false)
  const [clientType, setClientType] = useState("")
  const [isGTEstimateActive, setIsGTEstimateActive] = useState(false)
  const [openGTResponseModal, setOpenGTResponseModal] = useState(false)
  const [GTResponseMessage, setGTResponseMessage] = useState("")
  const [GTUrl, setGTUrl] = useState("")
  const [licensePlate, setLicensePlate] = useState("")
  const [make, setMake] = useState("")
  const [model, setModel] = useState("")
  const [odometer, setOdometer] = useState("")
  const [vin, setVin] = useState("")
  const [fuelmeter, setFuelMeter] = useState("")
  const [showVinModal, setShowVinModal] = useState(false)
  const [sogesaLoading, setSogesaLoading] = useState("loading")
  const [showSogesaModal, setShowSogesaModal] = useState(false)
  const [infoCarCode, setInfoCarCode] = useState("")
  const [personaList, setPersonaList] = useState([])
  const [uniqueSearch, setUniqueSearch] = useState([])
  const [reportMonth, setReportMonth] = useState(1)
  const [reportDownloading, setReportDownloading] = useState(false)
  const [configData, setConfigData] = useState({})
  const [editMode, setEditMode] = useState(false)
  const [carLevelEditData, setCarLevelEditData] = useState({})
  const [partLevelEditData, setPartLevelEditData] = useState([])
  const [partLevelDisplayData, setPartLevelDisplayData] = useState([])
  const [partCheckBox, setPartCheckBox] = useState([])
  const [editRow, setEditRow] = useState(false)
  const [editedData, setEditedData] = useState([])
  const [isMahindraClient, setIsMahindraClient] = useState(false)
  const [allowDamageModal, setAllowDamageModal] = useState(false)
  const [isHailDamage, setIsHailDamage] = useState(false)
  const [isSizeOfDamage, setIsSizeOfDamage] = useState(false)
  const [isInternalDamage, setIsInternalDamage] = useState(false)
  const [incrementalList, setIncrementalList] = useState([])
  const [incrementalSearchInspection, setIncrementalSearchInspection] = useState("")
  const [incrementalSearchDate, setIncrementalSearchDate] = useState("")
  const [incrementalSearchCarCondtion, setIncrementalSearchCarCondtion] = useState("")
  const [incrementalInspectionProcessingData, setIncrementalInspectionProcessingData] = useState([])
  const [incrementalInspectionData, setIncrementalInspectionData] = useState([])
  const [incrementalInspectionAvailableData, setIncrementalInspectionAvailableData] = useState([])
  const [incrementalInspectionRejectedData, setIncrementalInspectionRejectedData] = useState([])
  const [incrementalInspectionLoading, setIncrementalInspectionLoading] = useState(true)
  const [webAppVin, setWebAppVin] = useState("")

  return{
    username, password, setUsername, setPassword, language, setLanguage, loggedIn, setLoggedIn, module, setModule, isOpen, setIsOpen, open, setOpen, swipe, setSwipe, openMenu , setOpenMenu, selectOpen, setSelectOpen,
    preInspectionId, setPreInspectionId, role, setRole, languageList, setLanguageList, preInspectionList, setPreInspectionList, currentPreInspectionPage, setCurrentPreInspectionPage, totalPages, setTotalPages, 
    loading, setLoading, date, setDate, search, setSearch, showRemarkModal, setShowRemarkModal, remark, setRemark, webappType, setWebappType, urlgenList, setUrlgenList, totalUrlgenPages, setTotalUrlgenPages,
    currentUrlgenPage, setCurrentUrlgenPage, urlgenLoading, setUrlgenLoading, agentSearch, setAgentSearch, urlgenSearch, setUrlgenSearch, urlgenDate, setUrlgenDate, appTypeOpen, setAppTypeOpen,linkType, setLinkType,
    navLaunguageOpen, setNavLaunguageOpen, webAppEmail, setWebAppEmail, webAppPhone, setWebAppPhone, webAppFinalCount, setWebAppFinalCount, webAppPersonaId, setWebAppPersonaId, webAppId, setWebAppId, webAppLicencePlate, 
    setWebAppLicencePlate, webAppRepeat, setWebAppRepeat, webAppCaseID, setWebAppCaseID, photowiseDetails, setPhotowiseDetails, photowiseLoading, setPhotowiseLoading, agentApprove, setAgentApprove,
    selectedImage, setSelectedImage, policyNumber, setPolicyNumber, approvalType, setApprovalType, showPolicyModal, setShowPolicyModal, policyEmail, setPolicyEmail, policyLoading, setPolicyLoading, showApproveModal, setShowApproveModal,
    clickedPart, setClickedPart, searchInspection, setSearchInspection, openImageModal, setOpenImageModal, imageList, setImageList, urlList, setUrlList, showImageError, setShowImageError, openImageSuccessModal, setOpenImageSuccessModal,
    urlGenerating, setUrlGenerating, loginError, setLoginError, urlSubmitted, setUrlSubmitted, isProfileOpen, setIsProfileOpen, isNavProfileOpen, setIsNavProfileOpen, showCarRemarkModal, setShowCarRemarkModal, carRemark, setCarRemark,
    countryCode, setCountryCode, configPresent, setConfigPresent, clientType, setClientType, isGTEstimateActive, setIsGTEstimateActive,openGTResponseModal, setOpenGTResponseModal, GTResponseMessage, setGTResponseMessage,
    GTUrl, setGTUrl, vin, setVin, showVinModal, setShowVinModal, sogesaLoading, setSogesaLoading, showSogesaModal, setShowSogesaModal, infoCarCode, setInfoCarCode, personaList, setPersonaList, uniqueSearch, setUniqueSearch,
    reportMonth, setReportMonth, reportDownloading, setReportDownloading, configData, setConfigData, editMode, setEditMode, partLevelEditData, setPartLevelEditData, carLevelEditData, setCarLevelEditData, licensePlate, setLicensePlate,
    make, setMake, model, setModel, odometer, setOdometer, fuelmeter, setFuelMeter, partCheckBox, setPartCheckBox, editRow, setEditRow, partLevelDisplayData, setPartLevelDisplayData, editedData, setEditedData, isMahindraClient, setIsMahindraClient,
    allowDamageModal, setAllowDamageModal, isHailDamage, setIsHailDamage, isSizeOfDamage, setIsSizeOfDamage, isInternalDamage, setIsInternalDamage, incrementalList, setIncrementalList, incrementalSearchInspection, setIncrementalSearchInspection,
    incrementalSearchDate, setIncrementalSearchDate, incrementalSearchCarCondtion, setIncrementalSearchCarCondtion, incrementalInspectionProcessingData, setIncrementalInspectionProcessingData, incrementalInspectionData, setIncrementalInspectionData,
    incrementalInspectionAvailableData, setIncrementalInspectionAvailableData, incrementalInspectionRejectedData, setIncrementalInspectionRejectedData, incrementalInspectionLoading, setIncrementalInspectionLoading, webAppVin, setWebAppVin
  }

}

export default useAppData